import * as chameleon from "@chamaeleonidae/chmln";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import { repository, session } from "~/clientInstance";
export class InAppMessaging {
    static instance: InAppMessaging;
    public static async create(): Promise<InAppMessaging> {
        if (this.instance)
            return this.instance;
        this.instance = new InAppMessaging();
        await setupInAppMessaging();
        setupDesignTokens();
        return this.instance;
    }
    clear() {
        if (!window.chmln)
            return;
        window.chmln.clear();
        clearDesignTokens();
    }
}
async function setupInAppMessaging() {
    const CHAMELEON_ACCOUNT = "S5MXxSUXSwK14Z8keWBBd6Fq3w6UFIsisQh74967mTyL9P-1RWXG9-F6slQQ10ZwKLtXEa";
    const CHAMELEON_FAST_URL = "https://experiences.octopus.com/";
    const userPropertiesBffResponse = await repository.InAppMessaging.getUserProperties();
    const featureTogglesFromSession = session.featureToggles ?? [];
    const userProperties = {
        uid_hash: userPropertiesBffResponse.UniqueIdHash,
        displayName: userPropertiesBffResponse.DisplayName,
        username: userPropertiesBffResponse.Username,
        email: userPropertiesBffResponse.EmailAddress,
        emailAddress: userPropertiesBffResponse.EmailAddress,
        created: userPropertiesBffResponse.Created,
        version: userPropertiesBffResponse.Version,
        startVersion: userPropertiesBffResponse.StartVersion,
        installationId: userPropertiesBffResponse.InstallationId,
        enabledFeatureToggles: featureTogglesFromSession,
        ...(userPropertiesBffResponse.LicenseSerialNumberHash && {
            company: {
                uid: userPropertiesBffResponse.LicenseSerialNumberHash,
            },
        }),
    };
    try {
        chameleon.init(CHAMELEON_ACCOUNT, { fastUrl: CHAMELEON_FAST_URL });
        chameleon.identify(userPropertiesBffResponse.UniqueId, userProperties);
        logger.info("Identified user with username {username} with in-app messaging as {uid}.", { username: userProperties.username, uid: userPropertiesBffResponse.UniqueId });
    }
    catch (e) {
        logger.error("Failed to identify user with username {username} with in-app messaging as {uid}.", { username: userProperties.username, uid: userPropertiesBffResponse.UniqueId });
    }
}
const chameleonStyleNodeId = "chameleon-design-tokens";
const chameleonDesignTokens = `:root {
    --chmlnDialogBackgroundPrimary: ${themeTokens.color.dialog.background.primary};
    --chmlnTextPrimary: ${themeTokens.color.text.primary};
    --chmlnButtonBackgroundPrimaryDefault: ${themeTokens.color.button.background.primary.default};
    --chmlnButtonBackgroundPrimaryHover: ${themeTokens.color.button.background.primary.hover};
    --chmlnButtonTextPrimary: ${themeTokens.color.button.text.primary};
    --chmlnButtonBorderSecondary: ${themeTokens.color.button.border.secondary};
    --chmlnButtonBackgroundSecondaryDefault: ${themeTokens.color.button.background.secondary.default};
    --chmlnButtonBackgroundSecondaryHover: ${themeTokens.color.button.background.secondary.hover};
    --chmlnButtonTextSecondary: ${themeTokens.color.button.text.secondary};
}`;
function setupDesignTokens() {
    let styleElement = document.querySelector(`#${chameleonStyleNodeId}`);
    if (!styleElement) {
        styleElement = document.createElement("style");
        styleElement.setAttribute("id", chameleonStyleNodeId);
        document.head.appendChild(styleElement);
    }
    styleElement.innerHTML = chameleonDesignTokens;
}
function clearDesignTokens() {
    const styleElement = document.querySelector(`#${chameleonStyleNodeId}`);
    styleElement?.remove();
}
